import axios from '@/libs/axios'

export default {
  getAvailableIntegrations() {
    return axios.get('/integrations')
  },
  getIntegrations(organizationId) {
    return axios.get(`/integrations/organization/${organizationId}`)
  },
  getIntegration(integrationId) {
    return axios.get(`/integrations/${integrationId}`)
  },
  postIntegration(payload) {
    return axios.post('/integrations', payload)
  },
  patchIntegration(integrationId, payload) {
    return axios.patch(`/integrations/${integrationId}`, payload)
  },
}
