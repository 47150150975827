<template>
  <div>

    <b-row v-if="loading">
      <b-col
        cols="12"
        xl="10"
        offset-xl="1"
      >
        <rc-overlay
          show
          no-fade
          variant="white"
          rounded
          class="py-5 shadow"
        />
      </b-col>
    </b-row>

    <b-row v-if="activeIntegrations.length">
      <b-col
        cols="12"
        xl="10"
        offset-xl="1"
      >
        <b-card no-body>
          <b-card-title class="m-2">
            {{ $t('settings.integrations.active') }}
          </b-card-title>
          <div
            v-for="(integration, integrationKey) in activeIntegrations"
            :key="integrationKey"
          >
            <div
              v-for="(activeIntegration) in integration.active"
              :key="activeIntegration.id"
              class="p-2 border-top"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="2"
                  class="text-center"
                >
                  <b-img
                    class="integration-logo"
                    :src="isDark && integration.manifest.logoDark ? integration.manifest.logoDark : integration.manifest.logo"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="10"
                  class="mt-2 mt-md-0"
                >
                  <h4>{{ integration.manifest[locale].name }}</h4>
                  <div v-html="integration.manifest[locale].shortDescription" />

                </b-col>
                <b-col
                  cols="12"
                  class="text-right mt-1 mt-lg-0"
                >
                  <b-button
                    v-b-toggle.integration-sidebar
                    variant="primary"
                    @click="selectIntegration( integration, activeIntegration.id )"
                  >
                    {{ $t('shared.btn.manage') }}
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="availableIntegrations.length">
      <b-col
        cols="12"
        xl="10"
        offset-xl="1"
      >
        <b-card
          no-body
        >
          <b-card-title class="m-2">
            {{ $t('settings.integrations.available') }}
          </b-card-title>
          <div
            v-for="(integration, integrationKey) in availableIntegrations"
            :key="integrationKey"
            class="p-2 border-top"
          >
            <b-row>
              <b-col
                cols="12"
                md="2"
                class="text-center"
              >
                <b-img
                  class="integration-logo"
                  :src="isDark && integration.manifest.logoDark ? integration.manifest.logoDark : integration.manifest.logo"
                />
              </b-col>
              <b-col
                cols="12"
                md="10"
                class="mt-2 mt-md-0"
              >
                <h4>{{ integration.manifest[locale].name }}</h4>
                <div v-html="integration.manifest[locale].shortDescription" />

              </b-col>
              <b-col
                cols="12"
                class="text-right mt-1 mt-lg-0"
              >
                <b-button
                  v-b-toggle.integration-sidebar
                  variant="primary"
                  :disabled="integration.manifest.soon"
                  @click="selectIntegration( integration )"
                >
                  <span v-if="integration.manifest.soon">
                    {{ $t('shared.coming-soon') }}...
                  </span>
                  <span v-else>
                    {{ $t('shared.btn.add') }}
                  </span>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <integration-sidebar
      :visible="!!editingIntegration"
      :integration="editingIntegration"
      :config-id="editingIntegrationConfigId"
      @close="selectIntegration()"
      @integration-added="fetchIntegrations"
    />

  </div>
</template>

<script>

import listSetup from '@/views/organization/integrations/listSetup'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import IntegrationSidebar from '@/views/organization/integrations/IntegrationSidebar'

export default {
  components: {
    IntegrationSidebar,
    RcOverlay,
  },
  setup: listSetup,
}
</script>

<style lang="scss" scoped>
.integration-logo{
  width: 100%;
  max-width: 120px;
  max-height: 80px;
}
</style>
