import formValidation from '@core/comp-functions/forms/form-validation'
import {
  computed, nextTick, ref, watch,
} from '@vue/composition-api'
import Swal from 'sweetalert2'
import i18n from '@/libs/i18n'
import integrations from '@/http/requests/integrations'
import { cloneNested, parseRequestError } from '@/helpers/helpers'

export default (props, { emit }) => {
  const loading = ref(false)
  const processing = ref(false)
  const integrationConfig = ref({ config: {} })
  const locale = computed(() => i18n.locale)

  const reset = () => {
    integrationConfig.value = {}
  }

  const {
    refFormObserver,
    getValidationState,
    resetForm,
  } = formValidation(reset)

  watch(() => props.configId, configId => {
    if (configId) {
      processing.value = true
      integrations.getIntegration(configId)
        .then(res => {
          integrationConfig.value = cloneNested(res.data)
          nextTick(() => {
            refFormObserver.value.reset()
          })
        })
        .catch(err => parseRequestError(err))
        .finally(() => {
          processing.value = false
        })
    }
  })

  const processIntegration = () => {
    if (integrationConfig.value.id) {
      return integrations.patchIntegration(
        integrationConfig.value.id,
        integrationConfig.value,
      )
    }

    integrationConfig.value.name = props.integration.name
    return integrations.postIntegration(
      integrationConfig.value,
    )
  }

  const onSubmit = hideFunction => {
    processing.value = true

    processIntegration()
      .then(() => {
        if (integrationConfig.value.id) {
          window.toast.notify.success(i18n.t('settings.integrations.toast.updated'))
        } else {
          emit('integration-added')
          window.toast.notify.success(i18n.t('settings.integrations.toast.added'))
        }
        hideFunction()
      })
      .catch(err => parseRequestError(err))
      .finally(() => {
        processing.value = false
      })
  }

  const beforeHide = hideFunction => {
    if (refFormObserver.value.flags.touched) {
      Swal.fire({
        title: i18n.t('Twoje niezapisane zmiany zostaną utracone'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: i18n.t('shared.btn.confirm'),
        cancelButtonText: i18n.t('shared.btn.cancel'),
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            hideFunction()
          }
        })
    } else {
      hideFunction()
    }
  }

  return {
    loading,
    processing,
    locale,
    integrationConfig,
    refFormObserver,

    onSubmit,
    getValidationState,
    resetForm,
    beforeHide,
  }
}
