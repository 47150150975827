<template>
  <b-sidebar
    id="integration-sidebar"
    bg-variant="white"
    class="integration-sidebar-container"
    shadow
    backdrop
    no-header
    right
    no-close-on-esc
    no-close-on-backdrop
    no-enforce-focus
    @hidden="$emit('close')"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ configId ? $t('settings.integrations.edit') : $t('settings.integrations.add') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="beforeHide(hide)"
        />

      </div>

      <rc-overlay
        :show="processing"
        no-fade
        variant="white"
      >

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            v-if="integration"
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit(hide))"
            @reset.prevent="resetForm"
          >
            <!-- Full Name -->
            <validation-provider
              v-for="(formElement, formElementKey) in integration.manifest.configForm"
              :key="formElementKey"
              #default="validationContext"
              :name="formElement.texts[locale].label"
              rules="required"
            >
              <b-form-group
                :label="formElement.texts[locale].label"
                label-for="full-name"
              >
                <b-form-input
                  :id="`input-${formElementKey}`"
                  v-model="integrationConfig.config[formElement.model]"
                  :state="getValidationState(validationContext)"
                  :type="formElement.type"
                />
                <b-form-text>
                  {{ formElement.texts[locale].description }}
                </b-form-text>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ configId ? $t('shared.btn.update') : $t('shared.btn.add') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="beforeHide(hide)"
              >
                {{ $t('shared.btn.cancel') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </rc-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import integrationSidebarSetup from '@/views/organization/integrations/integrationSidebarSetup'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    RcOverlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    integration: {
      type: Object,
      required: false,
      default: () => {},
    },
    configId: {
      type: Number,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup: integrationSidebarSetup,
}
</script>

<style>
.integration-sidebar-container > #integration-sidebar {
  width: 40%;
  min-width: 576px;
}

@media (max-width: 576px) {
  .integration-sidebar-container > #integration-sidebar {
    width: 100%;
    min-width: auto;
  }
}
</style>
