import { computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import store from '@/store'
import integrations from '@/http/requests/integrations'
import { parseRequestError } from '@/helpers/helpers'
import i18n from '@/libs/i18n'

export default () => {
  const { skin } = useAppConfig()

  const me = computed(() => store.getters['auth/getUser'])
  const locale = computed(() => i18n.locale)
  const loading = ref(true)
  const isDark = computed(() => skin.value === 'dark')

  const editingIntegration = ref(null)
  const editingIntegrationConfigId = ref(null)

  const availableIntegrations = ref([])
  const activeIntegrations = ref([])

  const fetchIntegrations = () => {
    integrations.getIntegrations(me.value.organization.id)
      .then(res => {
        availableIntegrations.value = res.data.filter(integration => integration.active.length === 0)
        activeIntegrations.value = res.data.filter(integration => integration.active.length > 0)
      })
      .catch(err => parseRequestError(err))
      .finally(() => {
        loading.value = false
      })
  }
  fetchIntegrations()

  const selectIntegration = (integration = null, configId = null) => {
    editingIntegration.value = integration
    editingIntegrationConfigId.value = configId
  }

  return {
    me,
    locale,
    loading,
    isDark,

    editingIntegration,
    editingIntegrationConfigId,

    availableIntegrations,
    activeIntegrations,

    fetchIntegrations,
    selectIntegration,
  }
}
